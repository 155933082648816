import React from "react"

import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { bpSm, toEm } from ".."

export const UseInSchool: React.FC = ({ children, ...props }) => {
  const {
    girlRunning: { childImageSharp: girlRunning },
    girlBoy: { childImageSharp: girlBoy },
  } = useStaticQuery(graphql`
    query {
      girlRunning: file(relativePath: { eq: "girl_running1.png" }) {
        childImageSharp {
          gatsbyImageData(
            sizes: "calc((100vw * 380) / 1440)"
            quality: 80
            placeholder: TRACED_SVG
            tracedSVGOptions: { background: "#F6F6F6" }
            breakpoints: [960, 1024, 1920, 2100]
          )
        }
      }

      girlBoy: file(relativePath: { eq: "girl-boy-phone-hand.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            sizes: "calc((100vw * 380) / 1440)"
            quality: 80
            placeholder: TRACED_SVG
            tracedSVGOptions: { background: "#F6F6F6" }
            breakpoints: [960, 1024, 1920, 2100]
          )
        }
      }
    }
  `)

  return (
    <section
      css={{
        display: "flex",
        alignItems: "center",
        [bpSm]: {
          flexDirection: "column",
        },
      }}
      {...props}
    >
      <div
        css={{
          display: "grid",
          gridTemplateColumns: "auto auto",
          gridGap: toEm(32),
          [bpSm]: {
            alignSelf: "flex-start",
          },
        }}
      >
        <GatsbyImage
          alt="Girl running"
          image={getImage(girlRunning)!}
          css={{
            width: (100 * 380) / 1440 + "vw",
            [bpSm]: {
              width: (100 * 165) / 375 + "vw",
            },
          }}
        />
        <GatsbyImage
          alt="Looking at runbit app"
          image={getImage(girlBoy)!}
          css={{
            width: (100 * 380) / 1440 + "vw",
            [bpSm]: {
              width: (100 * 165) / 375 + "vw",
            },
          }}
        />
      </div>
      <div
        css={{
          padding: toEm(70),
          maxWidth: 925,
          [bpSm]: {
            alignSelf: "flex-start",
            padding: toEm(32),
            marginTop: toEm(42),
          },
        }}
      >
        {children}
      </div>
    </section>
  )
}
